.card-search-sm > .ant-card-body {
    padding: 0 12px 0 12px;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}

.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}

#rc-tabs-0-more {
    height: 35px;
    padding-top: 7px;
}

.btn-comm-alert-ok {
    background: #ffffff;
    color: #1f1f1f;
    border: 1px solid #d9d9d9;
    height: 28px;
    padding: 3px 7px 4px 7px;
}

/* oznet-display : tab */
.oznet-display {
    display: flex;
    flex-direction: column; /* 내부 요소들을 세로로 배열 */
    height: 100%;
    overflow: hidden;
}
.oznet-display .ant-tabs-content-holder {
    flex-grow: 1;
    height: 100%;
}
.oznet-display .ant-tabs-content-holder .ant-tabs-content {
    flex: 1; /* 모든 사용 가능한 공간을 차지하도록 설정 */
    display: flex;
    flex-direction: column; /* 내부 요소들을 세로로 배열 */
    overflow: hidden; /* 추가 요소를 숨김 */
    height: 100%;
}
.oznet-display .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane-active {
    flex: 1; /* 모든 사용 가능한 공간을 차지하도록 설정 */
    display: flex;
    flex-direction: column; /* 내부 요소들을 세로로 배열 */
    overflow: hidden; /* 추가 요소를 숨김 */
    height: 100%;
}

/* oznet-display : table */
.oznet-display .ant-table-wrapper {
    flex-grow: 1;
    height: 100%;
}
.oznet-display .ant-table-wrapper .ant-spin-nested-loading {
    flex-grow: 1; /* 모든 사용 가능한 공간을 차지하도록 설정 */
    display: flex;
    flex-direction: column; /* 내부 요소들을 세로로 배열 */
    overflow: hidden; /* 추가 요소를 숨김 */
    height: 100%;
}
.oznet-display .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}
.oznet-display .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
    display: flex;
    flex-direction: column; /* 내부 요소들을 세로로 배열 */
    overflow: hidden; /* 추가 요소를 숨김 */
    height: 100%;
}
.oznet-display .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container {
    flex-grow: 1; /* 모든 사용 가능한 공간을 차지하도록 설정 */
    display: flex;
    flex-direction: column; /* 내부 요소들을 세로로 배열 */
    overflow: hidden; /* 추가 요소를 숨김 */
}
.oznet-display .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body {
    flex-grow: 1;
    height:1px;
    overflow-y: auto !important;
}

/* 메인화면 스크롤 */
#rc-tabs-0-panel-\/app\/home {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-1-panel-\/app\/home {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-2-panel-\/app\/home {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-3-panel-\/app\/home {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-4-panel-\/app\/home {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-5-panel-\/app\/home {
    overflow: hidden auto;
    height:1px
}

/* modal move cursor */
.ant-modal-title {
    font-size: 20px !important;
}

.oznte-modal-move-cursor {
    cursor: move;
}


/*아이콘 페이지 스크롤 생성(임시)*/
#rc-tabs-0-panel-\/app\/components\/general\/icon {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-1-panel-\/app\/components\/general\/icon {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-2-panel-\/app\/components\/general\/icon {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-3-panel-\/app\/components\/general\/icon {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-4-panel-\/app\/components\/general\/icon {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-5-panel-\/app\/components\/general\/icon {
    overflow: hidden auto;
    height:1px
}

#rc-tabs-0-panel-\/app\/sample\/Sample014 {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-1-panel-\/app\/sample\/Sample014 {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-2-panel-\/app\/sample\/Sample014 {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-3-panel-\/app\/sample\/Sample014 {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-4-panel-\/app\/sample\/Sample014 {
    overflow: hidden auto;
    height:1px
}
#rc-tabs-5-panel-\/app\/sample\/Sample014 {
    overflow: hidden auto;
    height:1px
}

/* 컨텐츠가 2개 일때 */
.row-grid{
    height:100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 10px;
    gap: 10px;
}
.row-grid.row-fix1{grid-template-columns: auto 100px;}
.row-grid.row-fix2{grid-template-columns: auto 200px;}
.row-grid.row-fix3{grid-template-columns: auto 300px;}
.row-grid.row-fix4{grid-template-columns: auto 400px;}
.row-grid.row-fix5{grid-template-columns: auto 500px;}
.row-grid.row-fix6{grid-template-columns: auto 600px;}
.row-grid.row-fix7{grid-template-columns: auto 700px;}
.row-grid.row-fix8{grid-template-columns: auto 800px;}

.row-grid.row-fix-conv1{grid-template-columns: 100px auto;}
.row-grid.row-fix-conv2{grid-template-columns: 200px auto;}
.row-grid.row-fix-conv3{grid-template-columns: 300px auto;}
.row-grid.row-fix-conv4{grid-template-columns: 400px auto;}
.row-grid.row-fix-conv5{grid-template-columns: 500px auto;}
.row-grid.row-fix-conv6{grid-template-columns: 600px auto;}
.row-grid.row-fix-conv7{grid-template-columns: 700px auto;}
.row-grid.row-fix-conv8{grid-template-columns: 800px auto;}

.row-grid .n1{ grid-column: auto / span 1; }
.row-grid .n2{ grid-column: auto / span 2; }
.row-grid .n3{ grid-column: auto / span 3; }
.row-grid .n4{ grid-column: auto / span 4; }
.row-grid .n5{ grid-column: auto / span 5; }
.row-grid .n6{ grid-column: auto / span 6; }
.row-grid .n7{ grid-column: auto / span 7; }
.row-grid .n8{ grid-column: auto / span 8; }
.row-grid .n9{ grid-column: auto / span 9; }
.row-grid .n10{ grid-column: auto / span 10; }

.row-grid form .ant-card{ margin-bottom:0;}
.row-grid form .ant-card-body{overflow: auto; height:0; min-height:calc(100% - 43px); }


@media all and (max-width:1000px) {
    .row-grid-column{
        grid-template-columns: 1fr;
    }

}
.ant-menu-title-content{position:relative}
.menu-app-badge {
    background-color: #ff6b72;
    border-radius: 50px;
    height: 15px;
    width: auto;
    max-width: 33px;
    padding:0 5px;
    margin-left:5px;
    overflow:hidden;
    color: white;
}

body[data-theme='light'] input:read-only {
    background-color: #FFFFFF;
}

body[data-theme='dark'] input:read-only {
    background-color: #363f58;
}

body[data-theme='light'] textarea:read-only {
    background-color: #FFFFFF;
}

body[data-theme='dark'] textarea:read-only {
    background-color: #363f58;
}

body[data-theme='light'] .ant-picker-input input:read-only {
    background-color: rgba(230, 244, 255, 0);
}

body[data-theme='dark'] .ant-picker-input input:read-only {
    background-color: #606d99;
}

body[data-theme='light'] .oznet-comm-gfnSuccessModal .ant-modal-content,
body[data-theme='light'] .oznet-comm-gfnAlert .ant-modal-content,
body[data-theme='light'] .oznet-comm-gfnConfirm .ant-modal-content {
    background-color: #FFFFFF;
}

body[data-theme='dark'] .oznet-comm-gfnSuccessModal .ant-modal-content,
body[data-theme='dark'] .oznet-comm-gfnAlert .ant-modal-content,
body[data-theme='dark'] .oznet-comm-gfnConfirm .ant-modal-content {
    background-color: #363f58;
}

body[data-theme='light'] .oznet-comm-gfnSuccessModal .ant-modal-content .ant-modal-confirm-title,
body[data-theme='light'] .oznet-comm-gfnAlert .ant-modal-content .ant-modal-confirm-title,
body[data-theme='light'] .oznet-comm-gfnConfirm .ant-modal-content .ant-modal-confirm-title,
body[data-theme='light'] .oznet-comm-gfnSuccessModal .ant-modal-content .ant-modal-confirm-content,
body[data-theme='light'] .oznet-comm-gfnAlert .ant-modal-content .ant-modal-confirm-content,
body[data-theme='light'] .oznet-comm-gfnConfirm .ant-modal-content .ant-modal-confirm-content {
    color: rgba(0, 0, 0, 0.88);
}

body[data-theme='dark'] .oznet-comm-gfnSuccessModal .ant-modal-content .ant-modal-confirm-title,
body[data-theme='dark'] .oznet-comm-gfnAlert .ant-modal-content .ant-modal-confirm-title,
body[data-theme='dark'] .oznet-comm-gfnConfirm .ant-modal-content .ant-modal-confirm-title,
body[data-theme='dark'] .oznet-comm-gfnSuccessModal .ant-modal-content .ant-modal-confirm-content,
body[data-theme='dark'] .oznet-comm-gfnAlert .ant-modal-content .ant-modal-confirm-content,
body[data-theme='dark'] .oznet-comm-gfnConfirm .ant-modal-content .ant-modal-confirm-content {
    color: #b4bed2;
}

body[data-theme='dark'] .oznet-comm-gfnSuccessModal .ant-modal-content .btn-comm-success-ok,
body[data-theme='dark'] .oznet-comm-gfnConfirm .ant-modal-content .btn-comm-confirm-cancel {
    background-color: #283142;
    border-color: #4d5b75;
    color: #b4bed2;
    box-shadow: 0 2px 0 rgba(180, 190, 210, 0.02);
    padding: 3px 7px 4px 7px;
}

body[data-theme='dark'] .oznet-comm-gfnSuccessModal .ant-modal-content .btn-comm-success-ok:hover,
body[data-theme='dark'] .oznet-comm-gfnConfirm .ant-modal-content .btn-comm-confirm-cancel:hover {
    color: #699dff;
    border-color: #699dff;
}

body[data-theme='dark'] .oznet-comm-gfnAlert .ant-modal-content .btn-comm-alert-ok,
body[data-theme='dark'] .oznet-comm-gfnConfirm .ant-modal-content .btn-comm-confirm-ok {
    background: rgb(35, 120, 4) !important;
    color: white !important;
    padding-bottom: 1px !important;
}

body[data-theme='dark'] .oznet-comm-gfnAlert .ant-modal-content .btn-comm-alert-ok {
    border: 1px solid transparent !important;
}

/* 테이블 트리구조 접기,펼치기 버튼 CSS */
.oznet-display .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
.oznet-display .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
    margin-top: 0 !important;
}

/* 화면의 높이의 가운데에 위치하도록 수정 */
.oznet-comm-spin {
    max-height: 100% !important;
}

/* 결재상신 모달창 내 결재란 수직 상단 정렬 */
.oznet-display .oznet-table-app-line-vertical-top .ant-table-cell {
    vertical-align: top !important;
}

/* 결재상신 모달창 내 결재란 화면 xxs 사이즈일 경우 padding 좌우 4px 설정*/
.oznet-display .oznet-table-app-line-vertical-top td {
    padding-left: 4px !important;
    padding-right: 4px !important;
}


.row-grid2{
    height:100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.row-grid2 .n1{ grid-column: auto / span 1; }
.row-grid2 .n2{ grid-column: auto / span 2; }
.row-grid2 .n3{ grid-column: auto / span 3; }
.row-grid2 .n4{ grid-column: auto / span 4; }
.row-grid2 .n5{ grid-column: auto / span 5; }
.row-grid2 .n6{ grid-column: auto / span 6; }
.row-grid2 .n7{ grid-column: auto / span 7; }
.row-grid2 .n8{ grid-column: auto / span 8; }
.row-grid2 .n9{ grid-column: auto / span 9; }
.row-grid2 .n10{ grid-column: auto / span 10; }

/* 첨부파일 목록 텍스트 마우스 커서 변경 */
.oznet-upload .ant-upload-list-item-name {
    cursor: pointer;
}
.oznet-upload .ant-upload .ant-upload-btn {
    padding: 20px 0 20px 0 !important;
}

/* 메인 탭 CSS */
body[data-theme='light'] .ant-tabs-tab {
    border-top: 1px solid #c4cacd !important;
    border-right: 1px solid #c4cacd !important;
    border-bottom: 0 !important;
    border-left: 1px solid #c4cacd !important;
}

body[data-theme='light'] .ant-tabs-tab-active {
    background: #717171 !important;
}

body[data-theme='light'] .ant-tabs-tab-active .ant-tabs-tab-btn span {
    color: #FFFFFF;
}

body[data-theme='light'] .ant-tabs-tab-active button {
    color: #FFFFFF;
}

body[data-theme="light"] .ant-tabs-tab-active button :hover {
    color: #FFFFFF;
}

/* 모달 내부 탭 CSS (계약서 작성 모달창 탭 적용 중)*/
body[data-theme='light'] .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFFFFF !important;
}

/* 메인화면 공지사항 CSS */
.oznet-comm-notice-row-heigth {
    height: 38px;
}

.oznet-comm-notice-cursor {
    cursor: pointer;
}

/* select 포함 Table CSS */
.oznet-comm-table-row-height-31 {
    height: 31px;
}

/* 전표처리 CSS */
.oznet-comm-table-row-height-35 {
    height: 35px;
}

/* 기성마감보고서 CSS */
.oznet-comm-table-row-height-50 {
    height: 50px;
}
.oznet-comm-table-row-height-57 {
    height: 57px;
}
.oznet-comm-table-row-height-90 {
    height: 90px;
}
.oznet-comm-table-row-height-102 {
    height: 102px;
}

body[data-theme='light'] .oznet-comm-table-row-subtotal-background {
    background: #fff7e6;
}

body[data-theme='light'] .oznet-comm-table-row-non-hover td {
    background: #FFFFFF !important;
}

body[data-theme='dark'] .oznet-comm-table-row-subtotal-background {
    background: #363f58;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    cursor: pointer;
    min-height: 50px;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
}

/* Table cellEditing CSS 시작 */
body[data-theme='light'] .oznet-table-cell-input-non-toggle {
    padding: 3px 7px 2px 7px !important;
    background: #fdf5e4;
    cursor: pointer;
    min-height: 29px !important;
}

body[data-theme='dark'] .oznet-table-cell-input-non-toggle {
    padding: 3px 7px 2px 7px !important;
    background: #363f58;
    cursor: pointer;
    min-height: 29px !important;
}

.oznet-comm-table-row-input-edit-mode td {
    height: 29px;
}

.oznet-comm-table-row-input-edit-mode .ant-table-cell-ellipsis {
    padding: 0 0 0 0 !important;
}

.oznet-comm-table-row-input-edit-mode .ant-table-cell-ellipsis span {
    padding: 2px 7px 2px 7px !important;
}

.oznet-comm-table-row-input-edit-mode .ant-form-item-control-input {
    min-height: 29px !important;
}

.oznet-comm-table-row-input-edit-mode .ant-form-item-control-input input {
    border-radius: 0 !important;
}
/* Table cellEditing CSS 끝 */


body[data-theme='light'] .oznet-comm-table-sum-zero-index {
    background: #fbe8d8;
}
body[data-theme='dark'] .oznet-comm-table-sum-zero-index {
    background: #485374;
}

.ant-btn {
    padding: 2px 7px 2px 7px !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 8px 0 0 0;
}

.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    padding-left: 10px;
}

.ant-table-tbody-virtual-scrollbar.ant-table-tbody-virtual-scrollbar-horizontal {
    position: relative !important;
    visibility: visible !important;
    margin: 1px 0 2px 0 !important;
    height: 10px !important;
}

.ant-table-tbody-virtual-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

body[data-theme='light'] .ant-table-tbody-virtual-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5) !important;
}
body[data-theme='dark'] .ant-table-tbody-virtual-scrollbar-thumb {
    background: #424a58 !important;
}
